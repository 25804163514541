import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-tea-time',
  templateUrl: './book-tea-time.component.html',
  styleUrls: ['./book-tea-time.component.css']
})
export class BookTeaTimeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
