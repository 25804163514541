import { Component, OnInit } from '@angular/core';
import { HistoryService } from '../Services/history.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  history:any
  title: any
  baseurl:any
  desc:any
  Title:any
  constructor(private his: HistoryService) { }

  async ngOnInit() {

    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      
      this.his.history().subscribe(data=>{
        this.history = data.data;
        console.log(this.history)
        this.Title = data.data[0].attributes.Title;
        console.log(this.Title)
        this.desc = data.data[0].attributes.HistoryTextField;
        console.log(this.desc)
        this.title = data.data[0].attributes.Image.data;
       console.log(this.title)
      //  window.open(this.baseurl+this.title);
      },
      error => {
        console.log(error);
      });
      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  }

}
