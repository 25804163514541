import { Component, OnInit } from '@angular/core';
import { ClubService } from 'src/app/Services/club.service';
import { TournamentService } from 'src/app/Services/tournament.service';

@Component({
  selector: 'app-paf-islamabad-club',
  templateUrl: './paf-islamabad-club.component.html',
  styleUrls: ['./paf-islamabad-club.component.css']
})
export class PafIslamabadClubComponent implements OnInit {

  pafclub:any
  address:any
  email:any
  holes:any
  phone:any
  imgs:any
  baseurl:any
  Club:any
  constructor(private clubs: ClubService,private serv: TournamentService) { }

 async ngOnInit() {
    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

    this.club();

    // this.clubs.pafclub().subscribe(data=>{
    //   this.pafclub = data.data;
    //   console.log(this.pafclub)
    //   // this.address = data.data[0].attributes.Address;
    //   // console.log(this.address)
    //   // this.email = data.data[0].attributes.Email;
    //   // console.log(this.email)
    //   // this.holes = data.data[0].attributes.Holes
    //   // console.log(this.holes)
    //   // this.phone = data.data[0].attributes.Phone
    //   // console.log(this.phone)
    //   this.imgs = data.data[0].attributes.Image.data;
    //  console.log(this.imgs)
    // //  window.open(this.baseurl+this.title);
    // },
    // error => {
    //   console.log(error);
    // });
    // this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
}

club(){
  this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  this.serv.clubs().subscribe(data=>{
    this.Club = data.data;
    console.log(this.Club)
  },
  error => {
    console.log(error);
  });
 
}

}
