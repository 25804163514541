import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExecutiveCommitteeService {

  constructor(private _http:HttpClient,private router:Router) { }

  getmembers():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/executive-committees?populate=Image";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  committeebyid(Id:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/executive-committees/"+Id+"?populate=Image";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 3106a9b12800c5ab3920522edfb3fbbdcc0d06e6dd964fba8a474a31f28d205adf02a23937747d011cb42d9f25d55618b8a22ac6d511ebcb68303c7b68d701a08583929f67f201044f86769653026d486c0e529cc32e0fbf6b209e6bd8b7dc95823992ff9549ee0dade18d0706901b8b5878f8f3f9e29779ee1340848505ff18",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  
}
