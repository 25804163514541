import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { id } from 'date-fns/locale';
// import { Script } from 'vm';
import { GalleryService } from '../Services/gallery.service';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  galleryimg:any
   Imgs: any
  baseurl: any
  item:any
  title:any
  slideImg: any
  array: any[]
  selectedItem:any
  // img:any
  // parms: any;
  // event : {id: string;};
  selectedimg:any
  ser : {id: string;};
// @Input() galleryData: array[] = []  

  constructor(private serv: GalleryService,private routee: ActivatedRoute) { }

 async ngOnInit() {
  this.ser = {
    id: this.routee.snapshot.params['id'],
  }
  var flag=await window.localStorage.getItem("flag")
    if(flag=="1" && window.innerWidth<1024){
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  // var flag=await window.localStorage.getItem("flag")
  // console.log('this is flag' ,flag)
  // if(flag=="1" && window.innerWidth<1024){
  //   console.log(flag)
  //   window.localStorage.setItem("flag","0");
  //   window.location.reload()}

    
    this.serv.getgalleryimages(this.ser.id).subscribe(data => {
      this.galleryimg=data.data;
     this.title = data.data.attributes.Title;
     this.Imgs =data.data.attributes.Images.data;
    //  console.log(this.imgs)
     this.array = this.Imgs;
    //  this.selectedImg = this.galleryimg
     //console.log(data.data[0])
    },
      error => {
        console.log(error);
      });
      //this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','') 
  }
  showImg(item){
    this.selectedimg = item.attributes.url
    this.selectedItem=item
  }
  currentImage = 0;
  
  next(): void{
    let a=this.selectedItem.id
    this.Imgs.map((obj)=>{if(obj.id==a+1&&obj.id-1<this.Imgs[this.Imgs.length-1].id){
    this.showImg(obj)
  }})
   
    // this.currentImage = this.currentImage + 1;
    // if(this.currentImage > this.array.length -1){
    //   this.currentImage = 0;
    // }
    // this.array = this.imgs[this.currentImage];
  }
  prev(): void{
    let a=this.selectedItem.id
    this.Imgs.map((obj)=>{if(obj.id==a-1&&obj.id>this.Imgs[0].id){
    
    this.showImg(obj)
    
    
    }})
   
    // this.currentImage = this.currentImage - 1;
    // if(this.currentImage < 0){
    //   this.currentImage = this.array.length -1;
    // }
    // this.array = this.imgs[this.currentImage];
  }

}
