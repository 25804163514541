import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { HomepageComponent } from './homepage/homepage.component';
import { EventsComponent } from './events/events.component';
import { EventSingleComponent } from './event-single/event-single.component';
import { BookTeaTimeComponent } from './book-tea-time/book-tea-time.component';
import { FieldReservationComponent } from './field-reservation/field-reservation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { PosterComponent } from './tournaments/poster/poster.component';
import { PgfRecordComponent } from './tournaments/pgf-record/pgf-record.component';
import { SampleResultComponent } from './tournaments/sample-result/sample-result.component';
import { MasonryComponent } from './News/masonry/masonry.component';
import { PortfolioComponent } from './News/portfolio/portfolio.component';
import { SingleMasonryComponent } from './News/masonry/single-masonry/single-masonry.component';
import { SinglePortfolioComponent } from './News/portfolio/single-portfolio/single-portfolio.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { HistoryComponent } from './history/history.component';
import { PresidentmessageComponent } from './presidentmessage/presidentmessage.component';
import { IslamabadClubComponent } from './Clubs/islamabad-club/islamabad-club.component';
import { PafIslamabadClubComponent } from './Clubs/paf-islamabad-club/paf-islamabad-club.component';
import { MargallaGreensComponent } from './Clubs/margalla-greens/margalla-greens.component';
import { GardenCityComponent } from './Clubs/garden-city/garden-city.component';
import { NationalamutureComponent } from './gallery/nationalamuture/nationalamuture.component';
import { ExecutiveCommitteeComponent } from './executive-committee/executive-committee.component';
import { SingleExecutivecommitteeMemberComponent } from './single-executivecommittee-member/single-executivecommittee-member.component';
import { RankingComponent } from './ranking/ranking.component';
import { AchievementDetailComponent } from './achievement-detail/achievement-detail.component';

const routes: Routes = [
  {
    path:'',redirectTo: 'homepage', pathMatch: 'full'
  },
  {
    path: 'homepage',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: HomepageComponent, pathMatch: 'full'
      }
    ]
  },
   {
    path: 'events',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: EventsComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'event-single/:id',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: EventSingleComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'book-tea-time',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: BookTeaTimeComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'field-reservation',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: FieldReservationComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'gallery',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: GalleryComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'gallery/:id',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:GalleryComponent , pathMatch: 'full'
      }
    ]
  },
  {
    path: 'nationalamuture',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: NationalamutureComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'poster',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: PosterComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'pgf-record',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: PgfRecordComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'sample-result',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: SampleResultComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'masonry',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: MasonryComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'portfolio',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: PortfolioComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'single-masonry',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: SingleMasonryComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'single-portfolio',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: SinglePortfolioComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'pgfcalendars',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: TournamentsComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'history',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: HistoryComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'presidentmsg',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:PresidentmessageComponent , pathMatch: 'full'
      }
    ]
  },
  // {
  //   path: 'clubs/:id',
  //   component: AppLayoutComponent,
  //   children: [
  //     {
  //       path: '', component:IslamabadClubComponent , pathMatch: 'full'
  //     }
  //   ]
  // },
  {
    path: 'club/:id',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:IslamabadClubComponent , pathMatch: 'full'
      }
    ]
  },
  {
    path: 'clubs',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:PafIslamabadClubComponent , pathMatch: 'full'
      }
    ]
  },
  {
    path: 'executive_committee',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:ExecutiveCommitteeComponent , pathMatch: 'full'
      }
    ]
  },
  {
    path: 'executive_committee/:id',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:SingleExecutivecommitteeMemberComponent , pathMatch: 'full'
      }
    ]
  },
  {
    path: 'achievements',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:RankingComponent , pathMatch: 'full'
      }
    ]
  },
  {
    path: 'achievements/:id',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component:AchievementDetailComponent , pathMatch: 'full'
      }
    ]
  },
  // {
  //   path: 'margallaclub',
  //   component: AppLayoutComponent,
  //   children: [
  //     {
  //       path: '', component:MargallaGreensComponent , pathMatch: 'full'
  //     }
  //   ]
  // },
  // {
  //   path: 'gardencityclub',
  //   component: AppLayoutComponent,
  //   children: [
  //     {
  //       path: '', component:GardenCityComponent , pathMatch: 'full'
  //     }
  //   ]
  // }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
