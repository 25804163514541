import { Component, OnInit } from '@angular/core';
import { RankingService } from '../Services/ranking.service';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent implements OnInit {

  sample:any
  baseurl:any
  tags:string;
  ranks:any;
  imgs:any;
  sortedSample: any[];
  constructor(private ranking: RankingService) {
  
   }

 async ngOnInit() {
    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
      this.ranking.achievement().subscribe(async data=>{
      this.sample = data.data
      this.sortedSample = this.sample.sort((a, b) => a.attributes.Listing_Order - b.attributes.Listing_Order);
     console.log(this.sortedSample)

      for(let item of this.sample){
        item.attributes.achievement;
        this.tags = item.attributes.achievement;
      }
     this.ranks = this.tags.split(",");
     
    })
  }
  // achievementById(item){
  //   console.log(item)
  //   this.ranking.achievementbyId(item.id).subscribe(data => {
  //     this.sample=data.data;
  //     console.log(this.sample)
  //     window.localStorage.setItem("flag","1")
  //     // this.router.navigate(['/islamabadclub', this.singleclub.id]);
  //     window.location.href='/#/achievements/'+this.sample.id
  //     window.location.reload()
  //     // this.router.navigateByUrl(this.redirectTo+this.singleclub.id);
  //     },
  //       error => {
  //         console.log(error);
  //       });
  // }

}
