import { Component, OnInit } from '@angular/core';
import { TournamentService } from 'src/app/Services/tournament.service';

@Component({
  selector: 'app-poster',
  templateUrl: './poster.component.html',
  styleUrls: ['./poster.component.css']
})
export class PosterComponent implements OnInit {

  posetr:any
  title:any
  baseurl:any
  item:any
  selectedItem:any
  selectedimg:any
  constructor(private ser: TournamentService) { }

  async ngOnInit() {
    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}
    this.ser.posters().subscribe(data=>{
      this.posetr = data.data;
      console.log(this.posetr)
      this.title = data.data[0].attributes.posters.data;
     console.log(this.title)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  
  }
  
  showImg(item){
    

    this.selectedimg = item.attributes.url
    this.selectedItem=item
    console.log(this.selectedimg)
   
  }
}
