import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { EventsComponent } from './events/events.component';
import { EventSingleComponent } from './event-single/event-single.component';
import { BookTeaTimeComponent } from './book-tea-time/book-tea-time.component';
import { FieldReservationComponent } from './field-reservation/field-reservation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { PosterComponent } from './tournaments/poster/poster.component';
import { PgfRecordComponent } from './tournaments/pgf-record/pgf-record.component';
import { SampleResultComponent } from './tournaments/sample-result/sample-result.component';

import { MasonryComponent } from './News/masonry/masonry.component';
import { PortfolioComponent } from './News/portfolio/portfolio.component';
import { SingleMasonryComponent } from './News/masonry/single-masonry/single-masonry.component';
import { SinglePortfolioComponent } from './News/portfolio/single-portfolio/single-portfolio.component';
// import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import {RouterModule,Routes} from '@angular/router';
import { RankingComponent } from './ranking/ranking.component';
import { HistoryComponent } from './history/history.component';
import { PresidentmessageComponent } from './presidentmessage/presidentmessage.component';
import { IslamabadClubComponent } from './Clubs/islamabad-club/islamabad-club.component';
import { PafIslamabadClubComponent } from './Clubs/paf-islamabad-club/paf-islamabad-club.component';
import { MargallaGreensComponent } from './Clubs/margalla-greens/margalla-greens.component';
import { GardenCityComponent } from './Clubs/garden-city/garden-city.component';
import { NationalamutureComponent } from './gallery/nationalamuture/nationalamuture.component';
import { ScheduleModule, RecurrenceEditorModule, DayService, WeekService, WorkWeekService, MonthService, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ExecutiveCommitteeComponent } from './executive-committee/executive-committee.component';
import { SingleExecutivecommitteeMemberComponent } from './single-executivecommittee-member/single-executivecommittee-member.component';
import { AchievementDetailComponent } from './achievement-detail/achievement-detail.component';
import { OwlModule } from 'ngx-owl-carousel';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { FullCalendarModule } from '@fullcalendar/angular';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';

// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   interactionPlugin
// ]);

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    AppLayoutComponent,
    EventsComponent,
    EventSingleComponent,
    BookTeaTimeComponent,
    FieldReservationComponent,
    GalleryComponent,
    TournamentsComponent,
    PosterComponent,
    PgfRecordComponent,
    SampleResultComponent,
    MasonryComponent,
    PortfolioComponent,
    SingleMasonryComponent,
    SinglePortfolioComponent,
    RankingComponent,
    HistoryComponent,
    PresidentmessageComponent,
    IslamabadClubComponent,
    PafIslamabadClubComponent,
    MargallaGreensComponent,
    GardenCityComponent,
    NationalamutureComponent,
    ExecutiveCommitteeComponent,
    SingleExecutivecommitteeMemberComponent,
    AchievementDetailComponent,
  ],
  imports: [
    BrowserModule,
    ScheduleModule,
    RecurrenceEditorModule,
    AppRoutingModule,
    // GraphQLModule,
    HttpClientModule,
    OwlModule
  
    // PdfViewerModule,
    // FullCalendarModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
// 