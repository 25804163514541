import { Component, OnInit } from '@angular/core';
import { TournamentService } from 'src/app/Services/tournament.service';
@Component({
  selector: 'app-pgf-record',
  templateUrl: './pgf-record.component.html',
  styleUrls: ['./pgf-record.component.css']
})
export class PgfRecordComponent implements OnInit {

  pgfdata=[]
  tournamentrecord=[]
  baseurl:any
  poster:any
  test=[]
  selecteditm:any
  year:any
  supposee:any
  constructor(private tournamentser: TournamentService) { }

  async ngOnInit() {
    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}
      
    this.tournamentser.pgftournaments().subscribe(data=>{
      this.pgfdata = data.data;
      console.log(this.pgfdata)

      // this.poster = data.data.attributes.QualifySchoolResult.data[0].attributes.url;
      // console.log(this.poster)

    })
  }

  qulify(poster){
   
    // this.tournamentser.pgftournaments().subscribe(data=>{
    //   this.poster = item.attributes.QualifySchoolResult.data[0].attributes.url;
    //   console.log(this.poster)
    //   window.open(this.baseurl+ this.poster, '_blank')
    // })
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    console.log(poster)
    window.open(this.baseurl+ poster, '_blank')
  }

  tournamentdata(item){
    this.tournamentser.pgftournaments().subscribe(data=>{
      this.tournamentrecord = item.attributes.pgf_tournament_record_data.data;
      console.log(this.tournamentrecord)

      this.year = item.attributes.Name;
      console.log(this.year)
      // this.year = data.data[0].attributes.pgf_tournament_record.data[0].attributes.Name;
      // console.log(this.year)
    })
      this.tournamentser.pgftournaments().subscribe(data=>{
        this.poster=''
      this.poster = item.attributes.QualifySchoolResult.data[0].attributes.url;
      console.log(this.poster)
    })
  //   this.tournamentser.pgftournamentdatas().subscribe(data=>{
  //     this.test = data.data;
  //   console.log(this.test)
  // })
    // this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  }

  posterclick(item){
   console.log(item)
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    this.tournamentrecord = item.attributes.Poster.data.attributes.url;
    console.log(this.tournamentrecord)
    window.open(this.baseurl+ this.tournamentrecord)
    }

  resultclick(item){
    console.log(item)
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    this.tournamentrecord = item.attributes.Result.data.attributes.url;
    console.log(this.tournamentrecord)
    window.open(this.baseurl+ this.tournamentrecord)
    }
}
