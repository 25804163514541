import { Component, OnInit } from '@angular/core';
import { GalleryService } from 'src/app/Services/gallery.service';

@Component({
  selector: 'app-nationalamuture',
  templateUrl: './nationalamuture.component.html',
  styleUrls: ['./nationalamuture.component.css']
})
export class NationalamutureComponent implements OnInit {

  galleryimg:any
   imgs: any
  baseurl: any
  item:any
  title:any
  slideImg: any
  array: any[]
  selectedItem:any
  // img:any
  // parms: any;
  // event : {id: string;};
  selectedimg:any
  constructor(private ser: GalleryService) { }

 async ngOnInit() {

  var flag=await window.localStorage.getItem("flag")
  console.log('this is flag' ,flag)
  if(flag=="1" && window.innerWidth<1024){
    console.log(flag)
    window.localStorage.setItem("flag","0");
    window.location.reload()}

    
    this.ser.getgallery().subscribe(data => {
      this.galleryimg=data.data;
     console.log(this.galleryimg)
     this.title = data.data[0].attributes.Title;
     console.log(this.title)
     this.imgs =data.data[0].attributes.Images.data;
    //  console.log(this.imgs)
     this.array = this.imgs;
     console.log(this.array)
    //  this.selectedImg = this.galleryimg
     //console.log(data.data[0])
    },
      error => {
        console.log(error);
      });
      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','') 
  }
  // showImg(item){
    
  //   console.log(item)
  //   this.selectedimg = item.attributes.url
  //   this.selectedItem=item
  //   console.log(this.selectedimg)
   
  // }
  // currentImage = 0;
  showImg(item){
    console.log(item)
    this.selectedimg = item.attributes.url
    this.selectedItem=item
    console.log(this.selectedimg)
  }
  currentImage = 0;
  
  next(): void{
  //   let a=this.selectedItem.id
  //   console.log(a)
  //   console.log(this.imgs)
  //   this.imgs.map((obj)=>{if(obj.id==a+1&&obj.id-1<this.imgs[this.imgs.length-1].id){
  //   console.log(obj)
  //   this.showImg(obj)
  // }})
  let a=this.selectedItem.id
    console.log(a)
    this.imgs.map((obj)=>{if(obj.id==a+1&&obj.id-1<this.imgs[this.imgs.length-1].id){
    console.log(obj)
    this.showImg(obj)
  }})
   
   
  }
  prev(): void{
    //   let a=this.selectedItem.id
    //   this.imgs.map((obj)=>{if(obj.id==a-1&&obj.id>this.imgs[0].id){
    //   console.log(obj)
    //   this.showImg(obj)
    
    
    // }})
    let a=this.selectedItem.id
    console.log(a)
    this.imgs.map((obj)=>{if(obj.id==a-1&&obj.id>this.imgs[0].id){
    console.log(obj)
    
    this.showImg(obj)
    
    
    }})
   
  }

}
