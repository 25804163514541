import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { achievement } from '../Model/achievement';
import { RankingService } from '../Services/ranking.service';

@Component({
  selector: 'app-achievement-detail',
  templateUrl: './achievement-detail.component.html',
  styleUrls: ['./achievement-detail.component.css']
})
export class AchievementDetailComponent implements OnInit {

  baseurl:any;
  members:achievement;
  ser : {id: string;};
  imgs:any
  tags:string;
  ranks:any;
  constructor(private routee: ActivatedRoute,private ranking: RankingService) { }

  ngOnInit() {
    this.ser = {
      id: this.routee.snapshot.params['id'],
    }

    this.ranking.achievementbyId(this.ser.id).subscribe(data=>{
      this.members = data.data;
      console.log(this.members)
      this.tags = data.data.attributes.achievement;
      console.log(this.tags)
      this.imgs = data.data.attributes.Image.data.attributes.url;
     console.log(this.imgs)

     this.ranks = this.tags.split(",");
     console.log(this.ranks)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  }

}
