import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  
  constructor(private router: Router) { }

  async ngOnInit() {
    //@ts-ignore
    // window.loadGallery(true, 'a.thumbnail');
    this.router.events.subscribe(event => {
      console.log("code executed")
      if (event instanceof NavigationStart) {
        const targetUrl = new URL(event.url);
        if (targetUrl.hash.endsWith('/homepage')) {
          window.location.reload();
        }
      }
    });
    
    var flag=await window.localStorage.getItem("flag")
    if(flag=="1" && window.innerWidth<1024){
      window.localStorage.setItem("flag","0");
      window.location.reload()
    }
  }

}
