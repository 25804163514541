import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { id } from 'date-fns/locale';
import {EventsService} from '../Services/events.service';
// import { CalendarOptions, EventApi, EventClickArg, FullCalendarComponent } from '@fullcalendar/angular';
// import  dayGridPlugin from '@fullcalendar/daygrid';
import { title } from 'process';
import { EventSettingsModel, ResourcesModel, View } from '@syncfusion/ej2-angular-schedule';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { ODataV4Adaptor, Query } from '@syncfusion/ej2-data';

import { Ajax } from "@syncfusion/ej2-base";
import { UrlAdaptor } from "@syncfusion/ej2-data";
import { EventRenderedArgs, DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService, ScheduleComponent } from "@syncfusion/ej2-angular-schedule";

// import  timeGridPlugin from '@fullcalendar/timegrid';
// import  interactionPlugin from '@fullcalendar/interaction';
// import  esLocal from '@fullcalendar/core/locales/es';
// import { title } from 'process';
// import { DayService, WeekService, WorkWeekService, MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';


@Component({
  selector: 'app-events',
  providers: [ DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService,
    DragAndDropService ],
  templateUrl: './events.component.html',
  // template: '<ejs-schedule [currentView] = "setView" [selectedDate] = "setDate" [eventSettings] = "eventObject"></ejs-schedule>',

  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  EventsData:any
  date: Date;
  item:any;
  public events: any[];

  public readonly: boolean = true;
  public selectedDate: Date = new Date();
  public setView: View = "Month";
  
  
  // public setClick: = "getSingleEvent(item)";
  private dataManager: DataManager = new DataManager({
  //  url: 'https://ej2services.syncfusion.com/production/web-services/api/Schedule',
  // url: 'http://localhost:1337/api/single-events', 
  // adaptor: new ODataV4Adaptor,
  //  crossDomain: true
});
public eventSettings: EventSettingsModel = { dataSource: this.dataManager };

baseurl:any
  constructor(private es: EventsService, private router: Router) { }
  @Input() redirectTo: string = "/event-single/"

  async ngOnInit() {

    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    // var flag=await window.localStorage.getItem("flag")
    // console.log('this is flag' ,flag)
    // if(flag=="1" && window.innerWidth<1024){
    //   console.log(flag)
    //   window.localStorage.setItem("flag","0");
    //   window.location.reload()}
   
    this.es.getAllEvents().subscribe(data => {
      this.EventsData=data.data;
      console.log(data.data[0].attributes.Title)
      
      let E=[];
      this.EventsData.map((i)=>{
      E.push({
      Id:i.id,
      Subject:i.attributes.Title,
      StartTime:i.attributes.From,
      EndTime:i.attributes.To,
      })
      this.eventSettings = {dataSource:E}
      })
      console.log(E)
    },
      error => {
        console.log(error);
      });
      
      // document.getElementById("myBtn").addEventListener("click", displayDate);

      // function displayDate(e) {
      // // document.getElementById("demo").innerHTML = Date();
      // console.log("data",e)
// }
  }
  
  getSingleEvent(item){
    console.log(item)
    this.es.getAllEventsByID(item.Id).subscribe(data => {
      this.EventsData=data.data;
      console.log(this.EventsData)
      // this.router.navigate(['/#/event-single/'+ this.EventsData.id]);
      this.router.navigateByUrl(this.redirectTo+this.EventsData.id);

      // window.location.href='/#/event-single/'+this.EventsData.id
      window.localStorage.setItem("flag","1")

      },
        error => {
          console.log(error);
        });
  }
  // toggleWeekends() {
  //   this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
  // }
  
  //  currentEvents: EventApi[] = [];
  // handleEventClick(clickInfo: EventClickArg) {
  //   // this.router.navigate(['/event-single', this.EventsData.id]);
  //   if (this.router.navigate(['/event-single', this.EventsData.id])) {clickInfo.event.title}
  //     clickInfo.event.remove();
  // }
  
}
function foreach() {
  throw new Error('Function not implemented.');
}

