import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GalleryService } from '../Services/gallery.service';
import { RankingService } from '../Services/ranking.service';
import { TournamentService } from '../Services/tournament.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {

  pgfannual: any
  drawsmatch:any
  result:any
  baseurl:any
  pdf:any
  selectedItem:any
  title:any
  Mainpro:any
  Snprof:any
  Jnprof:any
  Jdtranking:any
  Amuture:any
  Snamuture:any
  Jnamuture:any
  Club:any
  Gallery:any
  sample:any
  singleclub:any
  singleGallery:any
  footerimages:any
  sortedlist:any
  sortedSample: any[];
  constructor(private ser: TournamentService,private http: HttpClient,private serv: GalleryService, private ranking: RankingService, private router: Router) { }
  @Input() redirectTo: string = "/club/"
  ngOnInit() {
    // this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','') 
    this.club();
    this.gallery();
    this.getfooterImages();
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  }
  // file(){
   
  //   this.ser.sampleformat().subscribe(async data=>{
  //   this.sample = data.data[0].attributes.File.data.attributes.url;
  //   console.log(this.sample)
  //   window.localStorage.setItem("flag","1")
  //   this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')

  //   window.open(this.baseurl+ this.sample,'_blank')
  // })
  // }

  // constitution(){
   
  //   this.ser.constitution().subscribe(async data=>{
  //   this.sample = data.data[0].attributes.File.data.attributes.url;
  //   console.log(this.sample)
  //   window.localStorage.setItem("flag","1")
  //   this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')

  //   window.open(this.baseurl+ this.sample,'_blank')
  // })
  // }
  
  
  
  // showImg(item){
    

  //   // this.pdf = item.data.data[0].attributes.calendarpdf.data.attributes.url;
  //   // this.selectedItem=item
  //   // console.log(this.pdf)
   
  // }
  // pgfCalendar(){
  //   this.ser.getpgfannualcalendar().subscribe(data=>{
  //     this.pgfannual = data.data;
  //     console.log(this.pgfannual)
  //     this.title = data.data[0].attributes.calendarpdf.data.attributes.url;
  //    console.log(this.baseurl+this.title)
  //    window.localStorage.setItem("flag","1")
  //    window.open(this.baseurl+this.title,'_bank');
  //   // window.open(this.baseurl+this.title, '_blank');

  //   },
  //   error => {
  //     console.log(error);
  //   });
  //   this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  // }

  club(){
    this.ser.clubs().subscribe(data=>{
      this.Club = data.data;
      console.log(this.Club)
    //   this.title = data.data[0].attributes.jnamuture.data.attributes.url;
    //  console.log(this.title)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
  }
  gallery(){
    this.serv.getgalleries().subscribe(data=>{
      this.Gallery = data.data;
      this.sortedSample = this.Gallery.sort((a, b) => a.attributes.Listing_Order - b.attributes.Listing_Order);
      console.log(this.sortedSample)
    //   this.title = data.data[0].attributes.jnamuture.data.attributes.url;
    //  console.log(this.title)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
  }
  getSingleGallery(item){
    console.log(item)
    this.serv.getgalleryimages(item.id).subscribe(data => {
      this.singleGallery=data.data;
      console.log(this.singleGallery)
      window.localStorage.setItem("flag","1")
      // this.router.navigate(['/islamabadclub', this.singleclub.id]);
      window.location.href='/#/gallery/'+this.singleGallery.id
      window.location.reload()
      // this.router.navigateByUrl(this.redirectTo+this.singleclub.id);
      },
        error => {
          console.log(error);
        });
  }

  getSingleClub(item){
    console.log(item)
    this.ser.clubbyid(item.id).subscribe(data => {
      this.singleclub=data.data;
      console.log(this.singleclub)
      window.localStorage.setItem("flag","1")
      // this.router.navigate(['/islamabadclub', this.singleclub.id]);
      window.location.href='/#/club/'+this.singleclub.id
      window.location.reload()
      // this.router.navigateByUrl(this.redirectTo+this.singleclub.id);
      },
        error => {
          console.log(error);
        });
  }
  galleryfun(){
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    window.localStorage.setItem("flag","1")
  }
  getfooterImages(){
    this.serv.getfooterimages().subscribe(data => {
      this.footerimages=data.data;
      this.sortedlist = this.footerimages.sort((a, b) => a.attributes.Listing_Order - b.attributes.Listing_Order);
      console.log(this.sortedlist);
      },
        error => {
          console.log(error);
        });
  }
  // navigateToHomePage() {
  //   this.router.navigateByUrl('/homepage', { skipLocationChange: true }).then(() => {
  //     window.location.reload();
  //   });
  // }
}
