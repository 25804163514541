import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RankingService {

  constructor(private _http:HttpClient) { }

  mainpro():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/mainprofessionals?populate=Mainpro";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  snprof():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/seniorprofessionals?populate=Seniorprofessional";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  jnprof():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/juniorprofessionals?populate=Jnprof";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  jdtranking():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/jdtrankings?populate=Jdtranking";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  amuture():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/amutures?populate=Amuture";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  snamuture():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/senioramutures?populate=snamuture";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  jnamuture():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/junioramutures?populate=jnamuture";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  achievement():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/achievements?populate=Image";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  achievementbyId(Id:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/achievements/"+Id+"?populate=Image";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  
}
