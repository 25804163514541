import { Component, OnInit } from '@angular/core';
import { PresidentmessageService } from '../Services/presidentmessage.service';

@Component({
  selector: 'app-presidentmessage',
  templateUrl: './presidentmessage.component.html',
  styleUrls: ['./presidentmessage.component.css']
})
export class PresidentmessageComponent implements OnInit {

  message:any
  Title:any
  desc:any
  imgs:any
  pname:any
  pdesc:any
  baseurl:any
  constructor(private msg: PresidentmessageService) { }

  async ngOnInit() {


    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    // var flag=await window.localStorage.getItem("flag")
    // console.log('this is flag' ,flag)
    // if(flag=="1" && window.innerWidth<1024){
    //   console.log(flag)
    //   window.localStorage.setItem("flag","0");
    //   window.location.reload()}

    this.msg.message().subscribe(data=>{
      this.message = data.data;
      console.log(this.message)
      this.Title = data.data[0].attributes.Title;
      console.log(this.Title)
      this.pname = data.data[0].attributes.Presidentname;
      console.log(this.pname)
      this.pdesc = data.data[0].attributes.Presidentdesc;
      console.log(this.pdesc)
      this.desc = data.data[0].attributes.Message;
      console.log(this.desc)
      this.imgs = data.data[0].attributes.Images.data;
     console.log(this.imgs)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
    //this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
}

}
