import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private _http:HttpClient,private router:Router) { }

  getgalleryImg():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/gallery-amuture-ladiess?populate=Images";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getgallery():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/gallery-national-amutures?populate=Images";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._http.get(FullUrl,requestOptions );
  }
  getgalleries():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/galleries?populate=Images";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",

    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._http.get(FullUrl,requestOptions );
  }

  getgalleryimages(Id:any):Observable<any>{
    let FullUrl = localStorage.getItem('BaseUrl') +"/galleries/"+Id+"?populate=Images";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  getfooterimages():Observable<any>{
    let FullUrl = localStorage.getItem('BaseUrl') +"/footer-images?populate=image";
    const headerDict = {
      // 'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      "Authorization": "Basic 90974cb271a3e9c32ea432f8657f6c6317ec5653cafad3fb1b9c0a7b2921969c14e4eb9060af6b6492d19803d30af88c002ea6daa2a43b8f560a28c599d0a6fbaa3f53d0d9f80f2cbd04f5b7eff4e097e59d683925d8d739920d6a4a477f6b1d0c3ae3cbce585a0ead454b528e05666ff310f9d56b64856756ad7d2813f4630e",
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
}
