import { Component, OnInit } from '@angular/core';
import { ClubService } from 'src/app/Services/club.service';

@Component({
  selector: 'app-garden-city',
  templateUrl: './garden-city.component.html',
  styleUrls: ['./garden-city.component.css']
})
export class GardenCityComponent implements OnInit {

  gardencityclub:any
  address:any
  email:any
  holes:any
  phone:any
  imgs:any
  baseurl:any
  constructor(private clubs: ClubService) { }

  ngOnInit() {
    this.clubs.gardenclub().subscribe(data=>{
      this.gardencityclub = data.data;
      console.log(this.gardencityclub)
      // this.address = data.data[0].attributes.Address;
      // console.log(this.address)
      // this.email = data.data[0].attributes.Email;
      // console.log(this.email)
      // this.holes = data.data[0].attributes.Holes
      // console.log(this.holes)
      // this.phone = data.data[0].attributes.Phone
      // console.log(this.phone)
      this.imgs = data.data[0].attributes.Image.data;
     console.log(this.imgs)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  }

}
