import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { clubsdata } from 'src/app/Model/club';
import { ClubService } from 'src/app/Services/club.service';
import { TournamentService } from 'src/app/Services/tournament.service';

@Component({
  selector: 'app-islamabad-club',
  templateUrl: './islamabad-club.component.html',
  styleUrls: ['./islamabad-club.component.css']
})
export class IslamabadClubComponent implements OnInit {

  Isbclub:any
  address:any
  email:any
  holes:any
  phone:any
  imgs:any
  clubImages:any
  fax:any;
  clubname:any
  baseurl:any
  President:any
  Secretary:any
  Manager:any 
  GroupCaptain:any
  ser : {id: string;};
  clubdata:clubsdata;
  Club:any[]
  constructor(private serv: TournamentService, private clubs: ClubService, private routee: ActivatedRoute) { }

 async ngOnInit() {
    this.ser = {
      id: this.routee.snapshot.params['id'],
    }
    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    // var flag=await window.localStorage.getItem("flag")
    // console.log('this is flag' ,flag)
    // if(flag=="1" && window.innerWidth<1024){
    //   console.log(flag)
    //   window.localStorage.setItem("flag","0");
    //   window.location.reload()}
      
    //this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    this.serv.clubbyid(this.ser.id).subscribe(data=>{
      this.Isbclub = data.data.attributes;
      console.log('isbclu b',this.Isbclub)
      this.President = this.Isbclub.President
      this.Secretary = this.Isbclub.Secretary
      this.Manager = this.Isbclub.Manager
      this.GroupCaptain = this.Isbclub.GroupCaptain
      this.clubname = this.Isbclub.Clubname
      this.address=this.Isbclub.Address;
      this.email=this.Isbclub.Email;
      this.phone=this.Isbclub.Phone;
      this.fax=this.Isbclub.Fax;
      this.holes=this.Isbclub.Holes;
      // this.imgs = "url('"+this.baseurl+data.data.attributes.Image.data.attributes.url+"')";
      this.imgs = data.data.attributes.Image.data.attributes.url;
      this.clubImages = data.data.attributes.ClubImages.data;
     console.log(this.imgs,this.clubImages)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
    
}
club(){
    this.serv.clubs().subscribe(data=>{
      this.Club = data.data.attributes;
      console.log(this.Club)
    //   this.title = data.data[0].attributes.jnamuture.data.attributes.url;
    //  console.log(this.title)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
   
  }
//   clubfunction(){
//     this.serv.clubs().subscribe(data=>{
//     this.Club = data.data;
//     console.log(this.Club)
//   //   this.title = data.data[0].attributes.jnamuture.data.attributes.url;
//   //  console.log(this.title)
//   //  window.open(this.baseurl+this.title);
//   })
// }
}
