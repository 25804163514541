import { Component, OnInit } from '@angular/core';
import { ExportService } from 'src/app/Services/export.service';
import { TournamentService } from 'src/app/Services/tournament.service';

@Component({
  selector: 'app-sample-result',
  templateUrl: './sample-result.component.html',
  styleUrls: ['./sample-result.component.css']
})
export class SampleResultComponent implements OnInit {

  sample:any
  baseurl:any
  constructor(private exportService: ExportService, private ser: TournamentService) { }

  async ngOnInit() {
    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}
    this.ser.sampleformat().subscribe(data=>{
      this.sample = data.data[0].attributes.File.data.attributes.url;
      console.log(this.sample)
    })
    
  }

  // export() {
  //   this.exportService.exportExcel(this.allPatient, 'Custom Report');
  // }
  exportOutcome() {
   
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')

    // window.open(this.baseurl+ this.sample,'_blank')
    this.exportService.exportExcel(this.sample, 'Sample Results');
  }
}
