import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { executiveMember } from '../Model/executive_member';
import { ExecutiveCommitteeService } from '../Services/executive-committee.service';

@Component({
  selector: 'app-single-executivecommittee-member',
  templateUrl: './single-executivecommittee-member.component.html',
  styleUrls: ['./single-executivecommittee-member.component.css']
})
export class SingleExecutivecommitteeMemberComponent implements OnInit {

  baseurl:any;
  members:executiveMember;
  ser : {id: string;};
  imgs:any
  constructor(private es: ExecutiveCommitteeService, private router: Router,private routee: ActivatedRoute) { }

  ngOnInit() {
    this.ser = {
      id: this.routee.snapshot.params['id'],
    }
    console.log(this.ser)

    
    this.es.committeebyid(this.ser.id).subscribe(data=>{
      this.members = data.data;
      console.log(this.members)
      this.imgs = data.data.attributes.Image.data.attributes.url;
     console.log(this.imgs)
    //  window.open(this.baseurl+this.title);
    },
    error => {
      console.log(error);
    });
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  }

  // committeememberbyid(item){
  //   this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  //   this.es.committeebyid(this.ser.id).subscribe(data=>{
  //     this.members = data.data.attributes;
      
  //     this.imgs = data.data.attributes.Image.data.attributes.url;
  //    console.log(this.imgs)
  //   //  window.open(this.baseurl+this.title);
  //   },
  //   error => {
  //     console.log(error);
  //   });
  // }

}
