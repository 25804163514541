import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Params, Router } from '@angular/router';
import { id } from 'date-fns/locale';
import { EventsService } from '../Services/events.service';
import { ActivatedRoute } from '@angular/router';
import { event } from '../Model/event';

@Component({
  selector: 'app-event-single',
  templateUrl: './event-single.component.html',
  styleUrls: ['./event-single.component.css']
})
export class EventSingleComponent implements OnInit {
  EventsData:event;
  router: any;
  parms: any;
  singleevent: any
  event : {id: string;};
  baseurl: any
  googleurl:any;
  image:any;
  // activatedRoute: any;
  constructor(private es: EventsService, private route: Router, private routee: ActivatedRoute) { }

  async ngOnInit(){

    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')

  //   var flag=await window.localStorage.getItem("flag")
  // console.log('this is flag' ,flag)
  // if(flag=="1" && window.innerWidth<1024){
  //   console.log(flag)
  //   window.localStorage.setItem("flag","0");
  //   window.location.reload()}


    this.event = {
      id: this.routee.snapshot.params['id'],
      
    }
    console.log(this.event)
    
    this.es.getAllEventsByID(this.event.id).subscribe(data => {
      this.EventsData=data.data;
     // var image = data.data[0].attributes.Image.data.attributes.url;
     
      //"+this.EventsData.attributes.Venue+"
      //Islamabad club golf club Murree road Islamabad
      //@ts-ignore
      this.googleurl='https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q='+this.EventsData.attributes.GoogleMap+'&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed';
     //@ts-ignore
      document.getElementById('googlemap').innerHTML=' <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="'+this.googleurl+ '" ></iframe>';
    
      console.log(this.EventsData)

      this.image = this.EventsData.Image.data.attributes.url;
     console.log(this.image);
    })
    this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','') 
    
// this.EventsData.From :String = new Date().toDateString();Islamabad club golf club Murree road Islamabad
  }
  
  // getSingleEvent(item:any){
  //   console.log(item)
  //   this.es.getAllEventsByID(item.id).subscribe(data => {
  //     this.EventsData=data.data;
  //     this.singleevent = this.EventsData.find(a=>a.id == item.id)
  //     console.log(this.singleevent)
  //     //  this.events = this.EventsData
  //     },
  //       error => {
  //         console.log(error);
  //       });
  // }

}
