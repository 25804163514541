import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExecutiveCommitteeService } from '../Services/executive-committee.service';

@Component({
  selector: 'app-executive-committee',
  templateUrl: './executive-committee.component.html',
  styleUrls: ['./executive-committee.component.css']
})
export class ExecutiveCommitteeComponent implements OnInit {

  baseurl:any;
  members:any;
  ser : {id: string;};
  imgs:any

  constructor(private es: ExecutiveCommitteeService, private router: Router,private routee: ActivatedRoute) { }

  async ngOnInit() {

    var flag=await window.localStorage.getItem("flag")
    console.log('this is flag' ,flag)
    if(flag=="1" && window.innerWidth<1024){
      console.log(flag)
      window.localStorage.setItem("flag","0");
      window.location.reload()}

      this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
    // var flag=await window.localStorage.getItem("flag")
    // console.log('this is flag' ,flag)
    // if(flag=="1" && window.innerWidth<1024){
    //   console.log(flag)
    //   window.localStorage.setItem("flag","0");
    //   window.location.reload()}

      // function to call executive committee data
        //this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
        this.es.getmembers().subscribe(data=>{
          this.members = data.data;
          console.log(this.members)
        },
        error => {
          console.log(error);
        });
       
  }
  getSinglemember(item){
    console.log(item)
    this.es.committeebyid(item.id).subscribe(data => {
      this.members=data.data;
      console.log(this.members)
      window.localStorage.setItem("flag","1")
      // this.router.navigate(['/islamabadclub', this.singleclub.id]);
      window.location.href='/#/executive_committee/'+this.members.id
      window.location.reload()
      // this.router.navigateByUrl(this.redirectTo+this.singleclub.id);
      },
        error => {
          console.log(error);
        });
  }
  // committeememberbyid(){
  //   this.baseurl = window.localStorage.getItem('BaseUrl').replace('/api','')
  //   this.es.committeebyid(this.ser.id).subscribe(data=>{
  //     this.members = data.data.attributes;
      
  //     this.imgs = data.data.attributes.Image.data.attributes.url;
  //    console.log(this.imgs)
  //   //  window.open(this.baseurl+this.title);
  //   },
  //   error => {
  //     console.log(error);
  //   });
  // }

}
